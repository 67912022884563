<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
	extends: HorizontalBar,
	props: ['chartdata', 'options'],
	watch: {
		chartdata: function() {
			this.renderChart(this.chartdata, this.options)
		}
	}
};
</script>

<style>
</style>