<template>
	<Main class="volume">
		<div class="mt-2">
			<h5>볼륨 테스트</h5>
		</div>
		<hr/>
		<div class="col">
			<b-card no-header class="mb-2">
				<b-input-group size="sm" class="mt-2 mb-2" prepend="테스트 대상 기기 갯수" append="개">
					<b-form-input 
						v-if="null != data.manager" 
						id="testCount" size="sm" disabled 
						v-model="data.manager.list.length"
					></b-form-input>
					<div v-else>테스트 대상 기기 정보가 없습니다.</div>
				</b-input-group>
				<b-button-toolbar size="sm" class="mb-2">
					<b-input-group size="sm" prepend="발생비율" append="%" class="mr-2">
						<b-form-input 
							id="percent" type="number" min="0" max="100" 
							v-model="data.rate"
							:disabled="!data.manager || data.manager.list.length <= 0"
						></b-form-input>
					</b-input-group>
					<b-input-group size="sm" prepend="테스트간격" append="Sec">
						<b-form-input 
							id="second" type="number" min="0" max="100" 
							v-model="data.interval"
							:disabled="!data.manager || data.manager.list.length <= 0"
						></b-form-input>
					</b-input-group>
					<b-button-group size="sm" class="ml-3">
						<b-btn 
							ref="boot" type="button" 
							size="sm" variant="danger" 
							@click="startStop" 
							:disabled="!data.manager || data.manager.list === null || data.manager.list.length === 0"
						>{{ (data.manager && data.manager.started) ? '기기 중지':'기기 시작'}}</b-btn>
						<b-button 
							size="sm" variant="primary" @click="startStressTest()"
							:disabled="!data.manager || data.manager.list.length <= 0"
						>테스트 시작</b-button>
						<b-button 
							size="sm" variant="primary" @click="stopStressTest()"
							:disabled="!data.manager || data.manager.list.length <= 0"
						>테스트 중지</b-button>
					</b-button-group>
				</b-button-toolbar>
				
			</b-card>
		</div>
		<div class="col">
			<b-card no-card-head class="mb-2">
				<b-row class="d-flex">
					<b-col  style="max-width:600px;">
						<bar-chart 
							ref="devChart"
							:width="300" :height="150" 
							:chartdata="devChart.data" 
							:options="devChart.options"
						></bar-chart>
					</b-col>
					<b-col class="ml-2" style="max-width:600px;">
						<bar-chart 
							ref="dbChart"
							:width="300" :height="150" 
							:chartdata="dbChart.data" 
							:options="dbChart.options"
						></bar-chart>
					</b-col>
				</b-row>
			</b-card>
		</div>
		<div class="col">
			<b-card class="mb-2">
				<b-form-group label="데이타 로그" label-for="deviceLog">
					<b-form-textarea id="deviceLog" ref="deviceLog" v-model="data.deviceLog" rows="6" max-rows="8" disabled></b-form-textarea>
				</b-form-group>
				<b-btn ref="save" type="button" size="sm" variant="info" @click="clearLog" class="float-right">로그삭제</b-btn>
			</b-card>
		</div>
	</Main>
</template>
<script>
import Swal from '@/components/swal.js'
import BarChart from '@/components/bar-chart'
import Chart from 'vue-chartjs'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'
import { Device, DevManager } from '../device';
import { mapState } from 'vuex'
import Main from './layout/main';

export default {
	name: 'volume',
	components: { Main, BarChart }, 
	computed: {
		...mapState({
			siteId: state => state.Server.siteId, 
			serverType: state => state.Server.serverType
		})
	},
	data() {
		return {
			data: {
				manager: null, 
				rate: 100, 
				interval: 100, 
				deviceLog: '', 
				stressTest: false, 
				updateCount: 0,
			},
			testCount: 0,
            devChart: {
                data: {
                    labels: ['미등록', '오프라인', '센싱오프', '정상', '화재', '침입'],
                    datasets: [
                        {
                            barPercentage: 0.6,
                            label: '센서 상태',
                            backgroundColor: 'rgba(52, 195, 143, 0.8)',
                            borderColor: 'rgba(52, 195, 143, 0.8)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
                            hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
                            data: [0, 0, 0, 0, 0, 0]
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                            gridLines: {
                                color: 'rgba(166, 176, 207, 0.1)'
                            }
                            }
                        ],
                        yAxes: [
                            {
                            gridLines: {
                                color: 'rgba(166, 176, 207, 0.1)'
                            }
                            }
                        ]
					}, 
					max: 4, 
					plugins: {
						datalabels: {
							display: true,
							color: 'white',
							font: {
								weight: 'bold'
							}, 
							formatter: function(value, context) {
								if (value <= 0) return '';
								return value
							}.bind(this)
						}
					}
                }
            },
            dbChart: {
                data: {
                    labels: ['미등록', '오프라인', '센싱오프', '정상', '화재', '침입'],
                    datasets: [
                        {
                            barPercentage: 0.6,
                            label: '서버DB 상태',
                            backgroundColor: 'rgba(52, 195, 143, 0.8)',
                            borderColor: 'rgba(52, 195, 143, 0.8)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
                            hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
                            data: [0, 0, 0, 0, 0, 0]
                        }
					]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                            gridLines: {
                                color: 'rgba(166, 176, 207, 0.1)'
                            }
                            }
                        ],
                        yAxes: [
                            {
                            gridLines: {
                                color: 'rgba(166, 176, 207, 0.1)'
                            }
                            }
						]
					}, 
					max: 4, 
					plugins: {
						datalabels: {
							display: true,
							color: 'white',
							font: {
								weight: 'bold'
							}, 
							formatter: function(value, context) {
								if (value <= 0) return '';
								return value
							}.bind(this)
						}
					}
                }
			}, 
			backendApi: process.env.VUE_APP_BACKEND_API
		}
	},
	mounted(){
		// if (Chart.plugins.unregister) Chart.plugins.unregister(ChartJsPluginDataLabels)
		// Chart.defaults.global.plugins.datalabels.display = false
		this.backendApi = this.serverType === 'dev' ? 'https://sofisems.com:8080/api':'https://sofisems.com/api';
		this.testCount = 0;
		this.data.manager = new DevManager();
        this.data.manager.loadDevices();
        const list = this.data.manager.list;
        if (list === null || list.length <= 0) {
            Swal.warning('먼저 기기 초기화 데이타를 로드 하십시오.');
		} else {
			this.updateDeviceData();
			this.updateDatabaseData();
		}
	}, 
	methods: {
		open (link) {
            this.$electron.shell.openExternal(link)
		}, 
		selectDevice() {
			if (this.data.manager === null) return
			const num = Math.round(this.data.manager.list.length * this.data.rate / 100)
			console.log('random devices: ', (num > 1 ? num : 1))
			this.testCount = num
			this.data.manager.randomWorkDevices((num > 1 ? num : 1), false)
		},
        startStop() {
			this.selectDevice()
			// this.data.manager.setWorkDevices(null)
            if (this.data.manager.started) {
                // stop devices
                this.data.manager.shutdown();
				setTimeout((This) => {
					This.updateDeviceData();
					This.updateDatabaseData();
				}, 5000, this)
            } else {
				// start devices
				this.data.updateCount = 0;
				this.data.stressTest = false;
				this.data.manager.boot(this.mqttCallback);
            }
        }, 
		startStressTest() {
			this.data.stressTest = true;
            const count = Math.round(this.data.interval * 2 / 3);
            this.data.manager.startOfEvents(count, this.eventCallback)
            this.addMessage('부하테스트를 시작 합니다.')
			Swal.success('부하테스트를 시작 합니다.')
			setTimeout((This) => {
				This.updateStressUI()
			}, 0, this)
		}, 
		stopStressTest() {
			this.data.manager.endOfEvents()
            this.addMessage('부하테스트를 종료 합니다.')
			Swal.success('부하테스트를 종료 합니다.')
			this.data.stressTest = false;
            setTimeout((This) => {
				This.updateDeviceData();
				This.updateDatabaseData();
			}, 20000, this)
		},
		updateStressUI() {
			this.updateDeviceData();
			this.updateDatabaseData();
			if (!this.data.stressTest) return;
			setTimeout((This) => {
				This.updateStressUI()
			}, 3000, this)
		},
        updateDeviceData() {
            console.log('step1')
            const states = this.data.manager.getDeviceStates()
            console.log('step2: ', states)
            const newData = {
                labels: ['미등록', '오프라인', '센싱오프', '정상', '화재', '침입'],
                datasets: [
                    {
                        barPercentage: 0.8,
                        label: '센서 상태',
                        backgroundColor: 'rgba(52, 195, 143, 0.8)',
                        borderColor: 'rgba(52, 195, 143, 0.8)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
                        hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
                        data: [
                            states.unregister, 
                            states.offline, 
                            states.standby, 
                            states.active, 
                            states.fire, 
                            states.invasion
						]
                    }
                ]
			}
			const newOptions = {
				scales: {
					xAxes: [{
						ticks: { 
							beginAtZero: true,
							min: 0, 
							max: (this.data.manager) ? this.data.manager.list.length: 1
						}
					}]
				}
			}
			// this.chart.options.scales.x.suggestedMin = 0
			// this.chart.options.scales.x.suggestedMax = (this.data.manager) ? this.data.manager.list.length: 1
			this.devChart.data = newData
			this.devChart.options = newOptions
        },
		updateDatabaseData() {
			console.log('siteId: ', this.siteId);
			this.$http.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoxLCJpZCI6ImhoaGFAZGlyZWN0aW9uc29mdC5jb20iLCJzaXRlX2lkIjo0LCJpYXQiOjE2MDI1NjM5MTJ9.CDWkwm5aDJY1GLprvuXmjO4jlzmAnHa2p2mHfOr4nZs';
			this.$http.get(`${this.backendApi}/state/total/full`, {
				params: {
					id: this.siteId
				}
			})
				.then(res => {
					if (!res.data.result) {
						throw new Error(res.data.message)
					}
					console.log('res: ', res)
					const states = res.data.row
					const newData = {
						labels: ['미등록', '오프라인', '센싱오프', '정상', '화재', '침입'],
						datasets: [
							{
								barPercentage: 0.8,
								label: '서버DB 상태',
								backgroundColor: 'rgba(52, 195, 143, 0.8)',
								borderColor: 'rgba(52, 195, 143, 0.8)',
								borderWidth: 1,
								hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
								hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
								data: [
									states.unregister, 
									states.offline, 
									states.standby, 
									states.active, 
									states.fire, 
									states.invasion
								]
							}
						]
					}
					const newOptions = {
						scales: {
							xAxes: [{
								ticks: { 
									beginAtZero: true,
									min: 0, 
									max: (this.data.manager) ? this.data.manager.list.length: 1
								}
							}]
						}
					}
					this.dbChart.data = newData
					this.dbChart.options = newOptions
				})
				.catch(error => {
					console.error(error);
				});
		},
        mqttCallback(topic, data) {
            if (topic.endsWith('initialize')) {
                const log = '[' + data.sn + ']는 ' + (data.regStatus < 0 ? '미등록' : '등록') + '입니다.'
				this.addMessage(log);
				
				this.data.updateCount += 1;
				if (this.data.updateCount % 10 == 0) {
					this.updateDeviceData();
					this.updateDatabaseData();
				}
			} else if (topic.endsWith('configuration')) {}
        },
        eventCallback(event, device) {
            // let message = null
            // if (event === 'fire') { message = '[' + device.sn + ']센서에서 화재가 감지되었습니다.'; }
            // if (event === 'invasion') { message = '[' + device.sn + ']센서에서 침입이 감지되었습니다.'; }
            // if (event === 'warning') { message = '[' + device.sn + ']센서에서 경고가 감지되었습니다.'; }
            // if (event === 'active') { message = '[' + device.sn + ']센서가 정상 상태로 전환되었습니다.'; }
            // if (event.endsWith('schedule start')) { message = '스케줄러에 의해 [' + device.sn + ']센서가 시작 되었습니다.'; }
            // if (event.endsWith('schedule stop')) { message = '스케줄러에 의해 [' + device.sn + ']센서가 중지 되었습니다.'; }
            // if (event.endsWith('schedule reboot')) { message = '스케줄러에 의해 [' + device.sn + ']센서가 리부트 되었습니다.'; }
			// if (message) this.addMessage(message)
        },
        clearLog() {
            this.data.deviceLog = '';
        }, 
		addMessage(message) {
            this.data.deviceLog += message;
            this.data.deviceLog += '\n';
            // set end of scroll
            setTimeout(() => {
				const deviceLog = document.getElementById('deviceLog');
				if (deviceLog) {
					deviceLog.scrollTop = deviceLog.scrollHeight;
					//deviceLog.scrollIntoView({behavior: "smooth", block: "end"})
				}
			}, 100)
        }
	}
}
</script>
